<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-md-4 d-flex justify-content-between align-items-center">
                <div class="header-title me-5">
                  <h4 class="card-title">Профиль</h4>
                </div>
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#add-button"
                >
                  Добавить
                </button>
              </div>
              <div class="col-md-4 offset-md-4 d-flex align-items-center justify-content-end">
                <button
                  type="button"
                  class="btn btn-primary"
                >
                  Сохранить
                </button>
              </div>
            </div>
          </div>
          <div class="card-body px-0">
            <div class="table-responsive">
              <table id="user-list-table" class="table" role="grid" data-toggle="data-table">
                <thead>
                <tr class="ligth">
                  <th data-type="number" v-for="(header, index) in tableHeaders" :key="index">{{ header }}</th>
                </tr>
                </thead>
                <draggable
                  v-model="buttonsData"
                  item-key="id"
                  tag="tbody"
                >
                  <template #item="{element}">
                    <tr>
                      <td>
                        <span style="cursor: pointer">
                          <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <g id="hamburger">
                              <g id="Group">
                                <path id="Path 2" d="M6.5 7H18.5" stroke="black" stroke-linecap="round" />
                                <path id="Path 2_2" d="M6.5 12H18.5" stroke="black" stroke-linecap="round" />
                                <path id="Path 2_3" d="M6.5 17H18.5" stroke="black" stroke-linecap="round" />
                              </g>
                            </g>
                          </svg>
                        </span>
                      </td>
                      <td>
                        <input type="text" class="form-control" v-model="element.name.ru" />
                      </td>
                      <td>
                        <input type="text" class="form-control" v-model="element.name.uz" />
                      </td>
                      <td>
                        <select v-model="element.parent" id="cost" type="number" class="form-select"
                                aria-label="Large file input example">
                          <option value="Англия">Англия</option>
                          <option value="Германия">Германия</option>
                          <option value="США">США</option>
                        </select>
                      </td>
                      <td>
                        <select v-model="element.neighbor" id="cost" type="number" class="form-select"
                                aria-label="Large file input example">
                          <option value="Регистрация">Регистрация</option>
                          <option value="Германия">Магазины</option>
                          <option value="США">Колл-центр</option>
                        </select>
                      </td>
                      <td class="text-center">
                        <div class="dropdown">
                          <a class="btn btn-primary dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                             data-bs-toggle="dropdown" aria-expanded="false">
                            Опции
                          </a>
                          <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <li>
                              <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#messages">Сообщения</a>
                            </li>
                            <li><a class="dropdown-item text-danger" href="#">Удалить</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </template>
                </draggable>
              </table>
            </div>
          </div>

          <div class="card-footer d-flex justify-content-center">
            <div class="bd-content">
              <CustomPagination
                :total-pages="20"
                :total="200"
                :per-page="10"
                :current-page="currentPage"
                @pagechanged="onPageChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal
      style="display: none"
      ref="ordersModal"
      mainClass="fade"
      :tabindex="-1"
      id="messages"
      ariaLabelled="addModalLabel"
      :ariaHidden="true"
      dialog-class="modal-lg modal-dialog-scrollable"
    >
      <model-header :dismissable="true"></model-header>

      <model-body class="px-5">
        <h1 class="text-center">Сообщения</h1>

        <div class="mt-4">
          <label for="text_ru" class="form-label">Сообщение на русском</label>
          <textarea id="text_ru" type="tel" class="form-control" aria-label="Large file input example"></textarea>

          <label for="text-uz" class="form-label mt-4">Сообщение на узбекском</label>
          <textarea id="text-uz" type="tel" class="form-control" aria-label="Large file input example"></textarea>
        </div>

        <div class="text-center my-5">
          <button type="button" class="btn btn-primary">Сохранить</button>
        </div>

      </model-body>
    </modal>

    <modal
      style="display: none"
      ref="ordersModal"
      mainClass="fade"
      :tabindex="-1"
      id="add-button"
      ariaLabelled="addModalLabel"
      :ariaHidden="true"
      dialog-class="modal-lg modal-dialog-scrollable"
    >
      <model-header :dismissable="true"></model-header>

      <model-body class="px-5">
        <h1 class="text-center">Новая кнопка</h1>

        <div class="row my-5">
          <div class="col-md-6">
            <label for="text-btn-ru" class="form-label">Текст кнопки (русский)</label>
            <input v-model="countryName" id="text-btn-ru" type="text" class="form-control"
                   aria-label="Large file input example">
          </div>
          <div class="col-md-6">
            <label for="parent" class="form-label">Родитель</label>
            <select id="parent" type="number" class="form-select" aria-label="Large file input example">
              <option value="Англия">Англия</option>
              <option value="Германия">Германия</option>
              <option value="США">США</option>
            </select>
          </div>
          <div class="col-md-6">
            <label for="text-btn-uz" class="form-label">Текст кнопки (узбекский)</label>
            <input v-model="countryName" id="text-btn-uz" type="text" class="form-control"
                   aria-label="Large file input example">
          </div>
          <div class="col-md-6">
            <label for="neighbor" class="form-label">Соседняя кнопка</label>
            <select id="neighbor" type="number" class="form-select" aria-label="Large file input example">
              <option value="Англия">Англия</option>
              <option value="Германия">Германия</option>
              <option value="США">США</option>
            </select>
          </div>
          <div class="col-md-6">
            <label for="text-btn-uz" class="form-label">Текст кнопки (английский)</label>
            <input v-model="countryName" id="text-btn-uz" type="text" class="form-control"
                   aria-label="Large file input example">
          </div>
          <div class="col-md-6 d-flex align-items-end justify-content-end">
            <button type="button" class="btn btn-primary">Сохранить</button>
          </div>
        </div>

      </model-body>
    </modal>
  </div>
</template>

<script>
import CustomPagination from '../../../../components/custom/pagination/CustomPagination'
import modal from '../../../../components/bootstrap/modal/modal'
import modelHeader from '../../../../components/bootstrap/modal/model-header'
import modelBody from '../../../../components/bootstrap/modal/model-body'
import useButtons from './useIndex'
import { ref } from 'vue'
import draggable from 'vuedraggable'

export default {
  name: 'ButtonsIndex',
  setup () {
    const { buttonsData, tableHeaders } = useButtons()
    const currentPage = ref(1)

    const onPageChange = (page) => {
      console.log(page)
      currentPage.value = page
    }

    return {
      buttonsData,
      currentPage,
      tableHeaders,
      onPageChange
    }
  },
  components: {
    CustomPagination,
    modal,
    modelBody,
    modelHeader,
    draggable
  }
}
</script>
