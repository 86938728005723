import { ref } from 'vue'

export default function () {
  const buttonsData = ref([
    {
      id: 1,
      name: {
        ru: 'Смена адреса доставки',
        uz: 'Do`konlar'
      },
      parent: '',
      neighbor: 'Регистрация'
    },
    {
      id: 2,
      name: {
        ru: 'Смена номера телефона',
        uz: 'Buyurtmalar'
      },
      parent: '',
      neighbor: 'Регистрация'
    },
    {
      id: 3,
      name: {
        ru: 'Архив заказов',
        uz: 'Ro\'yxatga olish'
      },
      parent: '',
      neighbor: 'Регистрация'
    }
  ])

  const tableHeaders = [
    'Порядок кнопок',
    'Текст кнопки (русский)',
    'Текст кнопки (узбекский)',
    'Родитель',
    'Соседняя кнопка',
    'Действия'
  ]

  return {
    buttonsData,
    tableHeaders
  }
}
